.base {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-radius: 40px;
}

.not_is_solved {
  background: #FFF8EE;
  color: #F9A825;

}

.is_solved {
  background: #EBF2EF;
  color: #005739;
}
