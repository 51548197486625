.maintenanceLogID{
  width: 7%;
  padding-left: 24px !important;
}

.maintenanceLogTypeNameField{
  width: 18%;
}

.maintenanceLogStoreNameField{
  width: 40%;
}

.maintenanceLogMachineSerialField{
  width: 12%;
}

.maintenanceLogImplementedAtField{
  width: 13%;
}

.maintenanceLogIconButtonsField{
  width: 10%;
}
