.machinePartDrawingNumberField{
  width: 7%;
  padding-left: 24px !important;
}

.machinePartTypeNameField{
  width: 23%;
}

.machinePartNameField{
  width: 40%;
}

.maximumActuatingCycleField{
  width: 10%;
}

.maximumOperatingHourField{
  width: 10%;
}

.machinePartIconButtonsField{
  width: 10%;
}
