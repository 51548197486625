.machineIDField{
  width: 10%;
  padding-left: 24px !important;
}

.machineSerialField{
  width: 12%;
}

.machineModelField{
  width: 10%;;
}

.machineSimImsiField{
  width: 15%;
}

.machineProductionDateField{
  width: 10%;
}

.machineStoreNameField{
  width: 30%;
}

.machineIconButtonsField{
  width: 13%;
}
