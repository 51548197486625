// ListToolbarのスタイルをsxでオーバライドできなかったため、CSS Modulesを使って上書きする
.toolbar {
  display: block !important;
  min-height: 48px !important;
  border-bottom: 1px solid #EDEAE3;

  & > form{
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    padding:0px;
    height: 100%;

    & > div {
      display: block !important;

      &:not(:last-child) {
        flex-grow: 1;
      }

      &:last-child {
        width: 0px;
      }

      // 子要素側で指定できなかったため、コンポーネント外の親要素で上書きする
      &[data-source=orderReceivedAtStart] {
        flex-grow: 0;
        width: 140px;
        border-right: 1px solid #EDEAE3;
      }

      &[data-source=orderReceivedAtEnd] {
        flex-grow: 0;
        width: 140px;
      }

      &[data-source=name] {
        flex-grow: 0;
        width: 30%;
      }

      &[data-source=drawingNumber] {
        flex-grow: 0;
        width: 35%;
      }

      &[data-source=machinePartTypeID] {
        flex-grow: 0;
        width: 35%;
      }

      &[data-source=machineSerial] {
        flex-grow: 0;
        width: 44.5%;
      }

      &[data-source=storeName] {
        flex-grow: 0;
        width: 44.5%;
      }

      &[data-className=errorLogMachineSerial] {
        flex-grow: 0;
      }
      
      &[data-source= errorOccurredAtStart] {
        flex-grow: 0;
      }

      &[data-source= errorOccurredAtEnd] {
        flex-grow: 0;
        border-right: 1px solid #EDEAE3
      }

      &[data-source=maintenancePerformedStartAt] {
        flex-grow: 0;
        width: 20%;
      }

      &[data-source=maintenancePerformedEndAt] {
        flex-grow: 0;
        width: 20%;
      }

      & fieldset {
        border-radius: 0;
        border: none;
      }
    }
  }
}

table {
  tr:last-child {
    td {
      border-bottom: none!important;
    }
  }
}

// 要素が空の場合にreact-adminのEmptyコンポーネントを描画している
// ダッシュボード全体のテーマで#fffとしているので、#000で上書きする
.empty {
  color: #000;
}