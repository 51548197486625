.errorLogStatusField{
  width: 12%;
  padding-left: 24px !important;
}

.errorLogEventIDField{
  width: 8%;
}

.errorLogLevelField{
  width: 10%;
}

.errorLogNameField{
  width: 20%;
}

.errorLogStoreNameField{
  width: 23%;
}

.errorLogMachineSerialField{
  width: 12%;
}

.errorLogOccurredAtField{
  width: 15%;
}
