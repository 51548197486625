.staffIdColumn{
  width: 10%;
  padding-left: 24px !important;
}

.staffNameColumn{
  width: 30%;
}

.staffDepartmentColumn{
  width: 25%;
}

.staffAuthGroupColumn{
  width: 5%;
}

.staffCreatedAtColumn{
  width: 5%;
}

.staffLoggedInAtColumn{
  width: 15%;
}

.staffIconButtonsColumn{
  width: 10%;
}
